export const locale = {
    lang: 'en',
    data: {
        'PAGE1': {
            'TITLE': 'Bloq 2 - Page 1',
        },
        'PAGE2': {
            'TITLE': 'Bloq 2 - Page 2',
        }
    }
};
