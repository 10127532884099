import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DbService } from './db.service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class InformeKpiService {

  constructor(private http: HttpClient, private db: DbService, private authSvc: AuthService, private helperSvc: HelperService) { }

  getInformeKpi(project, fechaInicio, fechaFin, page, pageSize, sortColum, sortDirection){
    const url = this.db.getInformeKpi();

    // console.log('URL:   '+url);
  
    return new Observable<any>(observer => {
      this.http.get(url+"?project="+project+"&fechaInicio="+fechaInicio+"&fechaFin="+fechaFin+"&page="+page+"&pageSize="+pageSize+"&sortColum="+sortColum+"&sortDirection="+sortDirection)
        .subscribe(data => {
          observer.next(data);
        }, err => {
          console.log("error informe kpi", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  getInformeIndicadores(params: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/informes/kpi-indicadores?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getInformeKPIIndicadores", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getInformeKPIIndicadores", err);
					let msgError = "Se ha producido un error cargando los datos";
          observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getInformeIndicadoresUnei(params: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/informes/kpi-indicadores-unei?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getInformeIndicadoresUnei", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getInformeIndicadoresUnei", err);
					let msgError = "Se ha producido un error cargando los datos";
                    observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getInformeIncumplidas(params: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/informes/incumplidas?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getInformeIncumplidas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getInformeIncumplidas", err);
					let msgError = err.error.message;
                    observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

  
}
