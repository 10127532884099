import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

	constructor(
			private http: HttpClient, 
			private authSvc: AuthService, 
			private helperSvc: HelperService
	) { }

	getAll(filtro: string): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				page: 0,
				pageSize: 50,
				filtro: filtro
			}

			const url = environment.API_URL + '/usuarios?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws getUsuarios", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getAllPaginated(numPage: number, pageSize: number, filtro: any): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
                page: numPage,
				pageSize: pageSize,
				filtro: filtro
			}

			const url = environment.API_URL + '/usuarios/paginated?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws getUsuarios", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getUsuario(idUsuario: number): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/usuarios/' + idUsuario;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws getUsuario", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    saveUsuario(body): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};
			const url = environment.API_URL + '/usuarios/new';
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws saveUsuario", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    updateUsuario(body): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};
			const url = environment.API_URL + '/usuarios';
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws updateUser", err);
					let msgError = "Se ha producido un error al actualizar el usuario";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    deleteUsuario(userId): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};
			const url = environment.API_URL + '/usuarios/remove/'+userId;
			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws updateUser", err);
					let msgError = "Se ha producido un error al actualizar el usuario";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getUserProfiles(userId): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};
			const url = environment.API_URL + '/usuarios/userProfiles/'+userId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws getUserProfiles", err);
					let msgError = "Se ha producido en getUserProfiles";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    addUserProfile(data): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/usuarios/userProfile/';
			this.http.post(url, data, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws removeUserProfiles", err);
					let msgError = "Se ha producido en removeUserProfiles";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    removeUserProfile(id): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/usuarios/userProfiles/' + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws removeUserProfiles", err);
					let msgError = "Se ha producido en removeUserProfiles";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
    
}
