import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
	providedIn: 'root'
})
export class ResiduosService {

	constructor(
		private http: HttpClient,
		private authSvc: AuthService,
		private helperSvc: HelperService
	) { }

	getHistorico(numPage: number, pageSize: number, columnOrder: string, columnDirection: string, filtros: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			// console.log("filtros getofertas", filtros);

			const params = {
				page: numPage,
				pageSize: pageSize,
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}

			if (filtros.et && filtros.et !== "") {
				params['et'] = filtros.et;
			}
			if (filtros.id_movimiento && filtros.id_movimiento !== "") {
				params['id_movimiento'] = filtros.id_movimiento;
			}
			if (filtros.marca && filtros.marca !== 0) {
				params['marca'] = filtros.marca;
			}
			if (filtros.modelo && filtros.modelo !== 0) {
				params['modelo'] = filtros.modelo;
			}
			if (filtros.tipo_elemento && filtros.tipo_elemento !== 0) {
				params['tipo_elemento'] = filtros.tipo_elemento;
			}
			if (filtros.fechaEntradaRange && filtros.fechaEntradaRange.startDate && filtros.fechaEntradaRange.startDate !== null 
					&& filtros.fechaEntradaRange.endDate && filtros.fechaEntradaRange.endDate !== null) {
				params['fechaInicioEntrada'] = moment(filtros.fechaEntradaRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinEntrada'] = moment(filtros.fechaEntradaRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}
			if (filtros.fechaResiduoRange && filtros.fechaResiduoRange.startDate && filtros.fechaResiduoRange.startDate !== null 
					&& filtros.fechaResiduoRange.endDate && filtros.fechaResiduoRange.endDate !== null) {
				params['fechaInicioResiduo'] = moment(filtros.fechaResiduoRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinResiduo'] = moment(filtros.fechaResiduoRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

			const url = environment.API_URL + '/residuos/historico?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getHistoricoResiduos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getHistoricoResiduos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	downloadCertificadoTratamiento(idTicket: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/residuos/download-certificado-tratatmiento/'+ idTicket;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws downloadCertificadoTratamiento", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws downloadCertificadoTratamiento", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	recuperarPiezas(idTicket: number, idCheckin: number, idActivo: number, idsPiezas: Array<number>) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const data = {
				idTicket: idTicket,
				idCheckin: idCheckin,
				idElementoInventario: idActivo,
				piezas: idsPiezas
			}
	
			const url = environment.API_URL + '/residuos/recuperar-piezas';
			this.http.post(url, data, options).subscribe(
				(response: any) => {
					console.log("ws recuperarPiezas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws recuperarPiezas", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	generarEtiquetaPieza(idPieza: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const headers = new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			});

			const url = environment.API_URL + '/residuos/generar-etiqueta-pieza/' + idPieza;
			this.http.get(url, {responseType: 'blob', headers: headers}).subscribe(
				(response: any) => {
					console.log("generar etiqueta pieza", response);
					const file = new Blob([response], {type: 'application/pdf'});
					const fileUrl = window.URL.createObjectURL(file);
					console.log("file url pdf", fileUrl);
					observer.next(fileUrl);
				},
				(err) => {
					console.log("error generar etiqueta pieza", err);
					observer.error(err);
				}
			);

			return {unsubscribe() {}};
		});
	}


}
