import { Component, Inject, OnDestroy, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject, Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-video',
  templateUrl: './dialog-video.component.html',
  styleUrls: ['./dialog-video.component.scss']
})
export class DialogVideoComponent implements OnInit {

    @ViewChild('iframeAitana') iframe: ElementRef;
    private _unsubscribeAll: Subject<any>;
    url: SafeResourceUrl;
    loadingIframe: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogVideoComponent>,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private sanitizer: DomSanitizer
    ) {
        this._unsubscribeAll = new Subject();

        this.loadingIframe = true;

        switch(this.data.extension){
            case "VIMEO":
                setTimeout(() => {
                    this.url = sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/"+this.data.vimeo_id);
                }, 400);
            break;
            case "GOOGLE":
                setTimeout(() => {
                    this.url = sanitizer.bypassSecurityTrustResourceUrl("https://drive.google.com/open?id="+this.data.google_drive_id);
                }, 400);
            break;
            case "YOUTUBE":
                setTimeout(() => {
                    this.url = sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+this.data.youtube_id);
                }, 400);
            break;
        }
    }

    ngOnInit(): void {
        if (this.data) {
            
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    showSnack(mensaje: string) {
        this.snackBar.open(mensaje, null, {
            duration: 2000
        });
    }

}
