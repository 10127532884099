import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class DispositivosService {

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

	getTiposElementos(idTicket?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idTicket: idTicket
			}
	
			const url = environment.API_URL + '/diagnosis/tipos-elementos' + (idTicket ? '?'+this.helperSvc.serializeData(params) : '');
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getTiposElementos", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getTiposElementos", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getMarcas(filtro: string, idTicket?: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				marca: filtro,
				idTicket: idTicket
			}

			const url = environment.API_URL + '/diagnosis/manufacturers?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getMarcas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getMarcas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getModelos(idMarca: number, filtro: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				marcaId: idMarca,
				modelo: filtro
			}

			const url = environment.API_URL + '/diagnosis/models?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getModelos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getModelos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
