import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';
import { ProyectosService } from 'app/services/proyectos/proyectos.service';

@Injectable({
  providedIn: 'root'
})
export class PanelControlService {

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService,
		public proyectosService: ProyectosService
	) { }

	getHistoricoProductividad(fechaInicio: string, fechaFin:String, idUsuario: number = null, logistica: boolean = false): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				fechaInicio: fechaInicio,
				fechaFin: fechaFin,
				idUsuario: idUsuario,
				logistica: logistica
			}

			const url = environment.API_URL + '/panelcontrol/historico-productividad?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getHistoricoProductividadPanelControl", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getHistoricoProductividadPanelControl", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getRepetidas(fechaInicio: string, fechaFin:String, idUsuario: number = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				fechaInicio: fechaInicio,
				fechaFin: fechaFin,
				idTecnico: idUsuario
			}

			const url = environment.API_URL + '/panelcontrol/repetidas?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getRepetidas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getRepetidas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getEmpleadosLogistica(): Observable<any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			const proyectos = this.proyectosService.getProjectsSeleted().projects;

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/panelcontrol/empleados-logisticaet';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEmpleadosLogisticaet", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEmpleadosLogisticaet", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getLogActuacionesLogistica(fechaInicio: string, idUsuario: number = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				fecha: fechaInicio,
				idTecnico: idUsuario
			}

			const url = environment.API_URL + '/panelcontrol/log-actuaciones-logistica?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCalculoLog", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCalculoLog", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getLogActuacionesEt(idUsuario: number = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				//fecha: fechaInicio,
				idTecnico: idUsuario
			}

			const url = environment.API_URL + '/panelcontrol/productividad-et?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getLogEt", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getLogEt", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	

	getLogActuacionesEtDepartamento(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				//fecha: fechaInicio,
				//idTecnico: idUsuario
			}

			const url = environment.API_URL + '/panelcontrol/productividad-et-departamento?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getLogEtDepartamento", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getLogEtDepartamento", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getPanelGeneral(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			const proyectos = this.proyectosService.getProjectsSeleted().projects;

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				proyectos: proyectos
			}

			const url = environment.API_URL + '/panelcontrol/general?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPanelGeneral", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPanelGeneral", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}






}
