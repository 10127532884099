<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative; display: flex; flex-direction: row; justify-content: space-between;">
    <h2 class="m-0 mb-8">{{ data?.titulo }}</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; top: -10px; right: -10px;">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" style="min-width: 40vw">
        <div class="div-iframe">
            <iframe *ngIf="url" [src]="url" (load)="loadingIframe = false;" target="_parent" width="100%" height="100%" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>