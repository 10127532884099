export const locale = {
    lang: 'es',
    data: {
        'PAGE1': {
            'TITLE': 'Bloque 1 - Pagina 1',
        },
        'PAGE2': {
            'TITLE': 'Bloque 1 - Pagina 2',
        }
    }
};
